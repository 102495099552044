<template>
  <div id="checkout">
    <v-dialog v-model="dialog"
      width="350px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <AddressList v-if="dialog" :shipping_promo="shipping_promo" @close="closeAddressList()"></AddressList>
    </v-dialog>
    <v-flex xs10 offset-xs1 v-if="cart && shipping" :class="isMobile ? (brand === '14' ? 'checkout' : 'checkout-m' ): 'checkout'">
      <p v-if="!isMobile" class="text">{{ $t('order.subtitle2')}}:</p>

      <div>
        <v-layout wrap class="mt-5 mb-3"> 
          <v-flex xs12 sm12 md6>
            <span class="title"> {{ $t('order.shipping-method')}} </span>
              <v-layout wrap class="mt-2">
                <v-flex xs12 sm12 md9>
                  <v-flex xs12 v-if="shippingOptions && shippingOptions.length > 0">
                    <table class="tbl">
                      <tbody v-for="(data, i) in shippingOptions" :key="i">
                        <tr class="radio">
                          <td width="5%">
                            <input class="item" type="radio" v-model="selectedShipping" :id="data.name" :value="data.name" @click="setShippingFee(data)">
                          </td>
                          <td width="95%">
                            <label class="text" :for="data.name">{{ data.name }}</label>
                             <p class="text"> {{data.service}} - 
                              <span :class="shipping_promo === 0 ? '': 'line-thru'"> {{ addComma(data.tarif)}} </span>
                              IDR 
                              <span v-if="shipping_promo"> ({{ addComma(data.tarif - shipping_promo) }} IDR)</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-flex>
                  <template v-else >
                    <v-flex xs12 v-if="shipping">
                      <p class="text"> {{shipping.name}}</p>
                      <p class="text"> {{shipping.service}} - 
                        <span :class="shipping_promo === 0 ? '': 'line-thru'"> {{ addComma(shipping.tarif)}} </span>
                        IDR 
                        <span v-if="shipping_promo"> ({{ addComma(shipping.tarif - shipping_promo) }} IDR)</span>
                      </p>
                    </v-flex>
                  </template>
                </v-flex>
              </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <span class="title"> {{ $t('order.shipping-address')}} </span>
            <div v-if="delivery">
              <p class="text"> <b>{{ delivery.penerima }} </b></p>
              <p class="text"> {{ delivery.alamat_lengkap }}</p>
              <p class="text"> {{ delivery.district }}</p>
              <p class="text"> {{ delivery.city }} {{ delivery.kode_pos}}</p>
              <p class="text"> {{ delivery.province}}</p>
              <p class="text"> Indonesia
              <p class="subtitle-gray"> {{ $t('order.phone')}}: {{ delivery.hp_penerima}}</p>
              <!-- <v-btn class="mt-5" tile outlined width="25%" height="30px" @click="toPage('Address')">
                <span class="btn-text"> {{ $t('order.change')}} </span>
              </v-btn> -->
              <v-btn class="mt-5" tile color="grey" outlined width="25%" height="30px" @click="openDialog()">
                  <span class="btn-text"> {{ $t('order.change')}} </span>
                </v-btn>
            </div>
          </v-flex>
        </v-layout>
        
        <v-layout wrap class="mt-5 mb-3">
          <v-flex xs12 sm12 md6 class="mt-5">
            <span class="title" v-if="payment"> {{ $t('order.payment')}}</span>
            <div v-for="(item, index) in group" :key="index">
              <span v-if="item[0].category === 'MANUAL'" class="subtitle"> {{ $t('order.bank-transfer')}} {{ $t('order.manual')}}</span>
              <span v-else class="subtitle"> {{ item[0].category}} {{ $t('order.automatic')}}</span>
              <table class="tbl">
                <tbody v-for="(data, i) in item" :key="i">
                  <tr :class="!data.is_enable ? 'radio disable-row ' : 'radio'">
                    <td width="5%">
                      <input class="item" type="radio" v-model="selectedPayment" :id="data.id_payment" :value="data.id_payment" @click="setPaymentFee()" :disabled="!data.is_enable">
                    </td>
                    <td width="20%">
                      <img v-bind:src="data.logo" :alt="data.logo">
                    </td>
                    <td width="75%">
                      <label :for="data.id_payment">{{ data.name }}</label>
                    </td>
                  </tr>
                  <tr class="radio" v-if="selectedPayment === data.id_payment">
                    <td colspan="3" class="description" v-html="data.deskripsi"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-flex>
         
          <v-flex xs12 sm12 md6 class="order-detail mt-5">
            <span class="title"> {{ $t('order.detail')}}</span>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-layout wrap class="mt-5 mb-3" v-for="(item, i) in cart" :key="i">
              <v-flex xs5 sm5 md5 class="mt-1">
                <img v-bind:src="item.data.url_image[0]" class="responsive" :alt="item.data.url_image[0]">
              </v-flex>
              <v-flex xs5 sm5 md7 class="mt-1 ml-2">
                <p class="text"> {{ item.data.product_name }}</p>
                <p class="f-smaller mt-0" v-if="!item.isBundling"> 
                  {{ item.size.sku_product }} - {{ item.size.ukuran }}
                </p>
                <div v-else>
                  <div v-for="(item, i) in item.bundling" :key="i"> 
                    <p class="text-2 mb-0">{{ item.product_name }}</p>
                    <p class="p-text-gray mb-0">{{ item.variant.ukuran }} - {{item.qty}} pcs</p>
                  </div>
                </div>
                <table class="tbl">
                  <tr>
                    <td class="text"> 
                      <span v-if="item.isBundling">{{ addComma(item.data.selling_price) }} IDR </span>
                      <span v-else>{{ addComma(item.size.selling_price) }} IDR </span>
                    </td>
                    <td class="text pr"> x{{ item.quantity }}</td>
                  </tr>
                </table>
              </v-flex>
            </v-layout>
            <v-divider class="mt-2 mb-2"></v-divider>
          </v-flex>
        </v-layout>
        
        <v-layout wrap class="mt-3 mb-3"> 
          <v-flex xs12 sm12 md6 class="mt-5" v-if="!isMobile">      
          </v-flex>
          <v-flex xs12 sm12 md6>
            <!-- <v-layout wrap v-if="!is_voucher"> 
              <v-flex xs8 class="mt-5">
                <v-text-field
                  class="mb-0"
                  outlined
                  v-model="promo_code"
                  :label="$t('order.promo-code')"
                  ref="promo_code"
                  :messages="getPromoCodeMessage()"
                  :disabled="!selectedPayment || !shipping || promo.total_promo !== 0"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs3 offset-xs1 class="mt-5">
                <v-btn outlined color="black" width="100%" height="56px" @click="checkPromo()" :disabled="!selectedPayment || !shipping">
                  <span class="btn-text" v-html="promo.id_promo ? 'DELETE' : ' APPLY'"> </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <div v-if="promo.description" class="subtitle-gray mb-5 pl-2 pt-2" style="border:1px solid green; color:green" v-html="promo.description"></div> -->
            <Voucher :total="total" :delivery_price="shipping.tarif" v-if="!is_promo" />
            <div class="amount">
              <table class="tbl">
                <tr>
                  <td class="text"> Subtotal :</td>
                  <td class="text pr"> {{ addComma(total) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.shipping-fee')}} :</td>
                  <td v-if="brand === '9'" class="text pr"> 
                    <span 
                      v-if="shipping && shipping.tarif_coret && shipping.tarif_coret > 0"
                      style="text-decoration: line-through;"
                    > 
                      {{ addComma(shipping.tarif_coret) }} IDR
                    </span>
                    <span
                      v-else-if="shipping && (!shipping.tarif_coret || shipping.tarif_coret === 0)"
                    > 
                      {{ addComma(shipping.tarif) }} IDR
                    </span>
                    <span v-else> 0 IDR</span>
                  </td>
                  <td v-else class="text pr"> 
                    <span v-if="shipping"> {{ addComma(shipping.tarif) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <tr v-if="brand === '9' && shipping && shipping.tarif_coret">
                  <td class="text"></td>
                  <td class="text pr">{{ addComma(shipping.tarif) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.service-fee')}} :</td>
                  <td class="text pr"> 
                    <span v-if="paymentFee"> {{ addComma(paymentFee) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.packing-fee')}} :</td>
                  <td class="text pr"> 
                    <span v-if="shipping"> {{ addComma(shipping.packing_fee) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text"> 
                    {{ $t('order.promo')}} : <br>
                    <span class="text-r">{{promo.name}}</span>
                  </td>
                  <td class="text-r pr"> - {{ addComma(promo.total_promo) }} IDR</td>
                </tr> -->
                <tr>
                  <td class="text"> 
                    Voucher : <br>
                    <span class="text-rv">{{voucher_name}}</span>
                  </td>
                  <td class="text-r pr"> - {{ addComma(voucher_price) }} IDR</td>
                </tr>
                <tr>
                  <td> <h3> TOTAL : </h3> </td>
                  <td class="pr"> <h3> {{ addComma((total + shipping.tarif + paymentFee + shipping.packing_fee - promo.total_promo - voucher_price)) }} IDR </h3> </td>
                </tr>
              </table>
            </div>
            <v-btn class="mt-5" tile color="black" dark width="100%" height="44px" @click="submit()">
              <span class="btn-text"> CHECKOUT </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
    <v-dialog v-model="popup"
      width="300px"
      transition="dialog-bottom-transition"
      persistent
    >
      <Popup 
        v-if="popup"
        :notif="popupNotif"
        @close="popup = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import apiCoversion from '@/api-coversion'
  import _ from 'lodash'
  import Voucher from '@/components/Voucher'
  import AddressList from '@/components/AddressList'
  import Popup from '@/components/Popup'

  export default {
    name: 'Checkout',
    components: { Voucher,AddressList, Popup },
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'cart',
        'cartNumber',
        'token',
        'shipping',
        'delivery',
        'voucher',
        'personalData',
        'shippingOptions'
      ])
    },
    watch: {
      voucher (val) {
        if (val) {
          this.voucher_price = this.voucher.total_promo
          this.voucher_name = this.voucher.name
          this.is_voucher = true
        } else {
          this.voucher_name = ''
          this.is_voucher = false
          this.voucher_price = 0
        }
      }
    },
    data () {
      return {
        total: 0,
        payment: null,
        promo_code: '',
        promo: { total_promo : 0 },
        selectedPayment: '',
        selectedShipping: '',
        paymentFee: 0,
        group: null,
        shipping_promo: 0,
        is_promo: false,
        is_voucher: false,
        voucher_price: 0,
        voucher_name: '',
        dialog: false,
        coversionResponse: '',
        popup: false,
        popupNotif: {
          title: 'Sorry, we hold your order',
          message: 'Looks like we can’t process the order. Complete your personal informations to continue process',
          action: 'Info',
          btnText: 'Go Now',
        },
        brand: process.env.VUE_APP_BRAND
      }
    },
    methods: {
      openDialog () {
        this.dialog = true
      },
      closeAddressList () {
        this.dialog = false
        this.selectedShipping = this.shipping ? this.shipping.name : ''
      },
      getPromoCodeMessage (){
        if (!this.selectedPayment || !this.shipping) {
          this.$t('order.promo-message1')
          return this.$t('order.promo-message1')
        } else {
          if (this.promo_code) {
            if (this.promo.description) {
              return this.$t('order.promo-message2')
            } else {
              return this.$t('order.promo-message3')
            }
          } else {
            return this.$t('order.promo-message4')
          }
        }
      },
      setPaymentFee () {
        setTimeout(()=>{
          this.paymentFee = Number(this.payment.find(val => val.id_payment === this.selectedPayment).payment_fee)
          if (process.env.VUE_APP_FB_ACCESS_TOKEN) {
            this.apiAddPaymentInfo()
          }
        },500)
      },
      setShippingFee(item) {
        this.$store.commit('setShipping', item)
      },
      setTotalAmount () {
        this.total = 0
        this.cart.map(item => {
          if (item.isBundling) {
            this.total += (Number(item.data.selling_price) * item.quantity)
          } else {
            this.total += (Number(item.size.selling_price) * item.quantity)
          }
        })
        this.getPayment()
        if (this.voucher) {
          this.updateVoucherCode()
        }

        setTimeout(()=>{
          this.initateCheckout()
        },200)
      },
      initateCheckout () {
        let ids = this.cart.map(item => {
          return item.isBundling ? item.data.product_code :item.size.sku_product
        })

        // let idsTiktok = this.cart.map(item => {
        //   return {
        //     id: item.isBundling ? item.data.product_code :item.size.sku_product,
        //     quantity: item.quantity
        //   }
        // })

        this.$analytics.fbq.event('InitiateCheckout', {
          content_type: 'product_group',
          content_ids: ids,
          value: this.total,
          nums_items: this.cartNumber,
          currency: 'IDR'
        })

        if (process.env.VUE_APP_PIXEL_TIKTOK) {
          window.ttq.track('InitiateCheckout', { 
            content_type: 'product_group',
            content_id: ids.toString(),
            // contents: idsTiktok,
            value: this.total,
            quantity: this.cartNumber,
            currency: 'IDR'
          })
        }

        if (process.env.VUE_APP_FB_ACCESS_TOKEN) {
          this.apiInitalCheckout(ids)
        }

        let products = this.cart.map(item => {
          return {
            name: item.data.product_name,
            id: item.data.product_code,
            price: item.isBundling ? item.data.selling_price : item.size.selling_price,
            brand: process.env.VUE_APP_NAME,
            category: '',
            variant: item.isBundling ? '' : item.size.ukuran,
            size: item.isBundling ? '': item.size.ukuran,
            url: item.url,
            img: item.data.url_image[0],
            quantity: item.quantity,
            status: item.data.status
          }
        })

        let productsGA4 = this.cart.map(item => {
          return {
            item_id: item.isBundling ? item.data.product_code : item.size.sku_product,
            item_name: item.data.product_name,
            item_variant: item.isBundling ? item.data.product_code : item.size.ukuran,
            price: item.isBundling ? item.data.selling_price : item.size.selling_price,
            quantity: item.quantity
          }
        })

        window.gtag("event", "begin_checkout", {
          currency: "IDR",
          value: this.total,
          items: productsGA4
        });

        window.dataLayer.push({
          'event': 'checkout',
          'ecommerce': {
            'checkout': {
              'products': products
            }
          }
        });
      },
      getPayment () {
        if (this.shipping) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            total_transaction: this.total,
            delivery_price: this.shipping.tarif
          }

          axios
            .get('/api/v1/orders/payment')
            .then(response => {
              this.payment = response.data.data
              this.groupingPayment(response.data.data)
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      checkPromo () {
        if (this.promo.id_promo) {
          this.promo_code = ''
          this.promo = { total_promo : 0 }
          this.shipping_promo = 0
          this.is_promo = false
        } else {
          this.$store.commit('setIsLoading', true)

          let body = {
            kode_promo: this.promo_code,
            total_transaction: this.total,
            delivery_price: this.shipping.tarif,
            products:  this.cart.map(item => {
              return {
                sku_product: item.size.sku_product,
                qty: item.quantity
              }
            })
          }

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token
          }

          axios
            .post('/api/v1/orders/promo', body)
            .then(response => {
              this.promo = response.data.data
              if (this.promo.type === 'ONGKIR') {
                this.shipping_promo = this.promo.total_promo
              }
              this.is_promo = true
            })
            .catch(error => {
              this.is_promo = false
              this.promo_code = ''
              this.promo = { total_promo : 0 }
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      groupingPayment (data) {
        this.group = _.groupBy(data, 'category')
      },
      getSkuProduct (item) {
        if (item.isBundling) {
          return item.bundling.map(value => {
            return {
              sku_product: value.variant.sku_product,
              qty : value.qty
            }
          })
          // return []
        } else {
          return item.size.sku_product
        }
      },
      submit () {
        // if (this.isCompletePersonalData(this.personalData)) {
        //   this.checkout()
        // } else {
        //   this.popup = true
        // }
        this.checkout()
      },
      checkout () {
        if (this.selectedPayment && this.shipping.id) {

          if (!this.promo_code || (this.promo_code && this.promo.description)) {
            this.$store.commit('setIsLoading', true)

            let products = this.cart.map(item => {
              return {
                id: item.isBundling ? item.data.product_code : item.size.sku_product,
                sku_product: this.getSkuProduct(item),
                size: item.size.ukuran,
                product_name: item.data.product_name,
                product_code: item.data.product_code,
                quantity: item.quantity,
                qty: item.quantity
              }
            })

            let ids = this.cart.map(item => {
              return item.isBundling ? item.data.product_code :item.size.sku_product
            })

            // let idsTiktok = this.cart.map(item => {
            //   return {
            //     id: item.isBundling ? item.data.product_code :item.size.sku_product,
            //     quantity: item.quantity
            //   }
            // })

            let product = this.cart.map(item => {
              return {
                name: item.data.product_name,
                id: item.data.product_code,
                price: item.isBundling ? item.data.selling_price : item.size.selling_price,
                brand: process.env.VUE_APP_NAME,
                category: '',
                variant: item.isBundling ? '' : item.size.sku_product,
                size: item.isBundling ? '' : item.size.ukuran,
                quantity: item.quantity,
                url: item.url,
                img: item.data.url_image[0],
                status: item.data.status
              }
            })

            let productsGA4 = this.cart.map(item => {
              return {
                item_id: item.isBundling ? item.data.product_code : item.size.sku_product,
                item_name: item.data.product_name,
                 item_variant: item.isBundling ? item.data.product_code : item.size.ukuran,
                price: item.isBundling ? item.data.selling_price : item.size.selling_price,
                quantity: item.quantity
              }
            })

            axios.defaults.headers = {
              id_brand: process.env.VUE_APP_BRAND,
              token: this.token
            }

            axios
              .post('/api/v1/orders/checkout_new',{
                // service: this.shipping.service,
                id_delivery_method: this.shipping.id,
                id_payment: this.selectedPayment,
                id_promo: this.setIdPromo(),
                kode_promo: this.setPromoCode(),
                use_promo: this.setPromoValue(),
                products: products,
                id_warehouse: 1
              })
              .then(response => {
                if (process.env.VUE_APP_FB_ACCESS_TOKEN) {
                  this.apiPurchase(ids)
                }
                
                this.$analytics.fbq.event('Purchase', {
                  content_type: 'product_group',
                  content_ids: ids,
                  value:  (this.total + this.shipping.tarif + this.paymentFee + this.shipping.packing_fee - this.promo.total_promo - this.voucher_price),
                  nums_items: this.cartNumber,
                  currency: 'IDR'
                })

                if (process.env.VUE_APP_PIXEL_TIKTOK) {
                  window.ttq.track('PlaceAnOrder', { 
                    content_type: 'product_group',
                    content_id: ids.toString(),
                    // contents: idsTiktok,
                    value:  (this.total + this.shipping.tarif + this.paymentFee + this.shipping.packing_fee - this.promo.total_promo - this.voucher_price),
                    quantity: this.cartNumber,
                    currency: 'IDR'
                  })
                }

                window.gtag("event", "purchase", {
                  currency: "IDR",
                  value: (this.total + this.shipping.tarif + this.paymentFee + this.shipping.packing_fee - this.promo.total_promo - this.voucher_price),
                  transaction_id: response.data.data.id_sales_header,
                  tax: 0,
                  shipping: this.shipping.tarif,
                  coupon: this.voucher_name,
                  items: productsGA4
                });

                window.dataLayer.push({
                  'event': 'purchase',
                  'ecommerce': {
                    'purchase': {
                      'actionField': {
                        'id': response.data.data.id_sales_header,      // Transaction ID. Required for purchases and refunds.
                        'affiliation': 'Online Store',
                        'revenue': (this.total + this.shipping.tarif + this.paymentFee + this.shipping.packing_fee - this.promo.total_promo - this.voucher_price),                     // Total transaction value (incl. tax and shipping)
                        'tax':'0',
                        'shipping': this.shipping.packing_fee,
                        'coupon': this.voucher_name
                      },
                      'products': product
                    }
                  }
                }); 

                setTimeout(() => {
                  this.$router.push({ name: 'Order-Detail', params: { id: response.data.data.id_sales_header} })
                  this.$store.commit('setSnackbar', true)
                  this.$store.commit('setText', response.data.message)
                  this.$store.commit('setCart', [])

                  localStorage.removeItem('prepp_c')
                  localStorage.removeItem('prepp_v')
                  this.$store.commit('setVoucher', null)
                  this.$store.commit('setIsLoading', false)
                },3000)
              })
              .catch(error => {
                this.$store.commit('setIsLoading', false)
                this.errorHandling(error)
              })
          } else {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.$t('order.check-promo-code-first'))
          }
        } else {
          if (!this.selectedPayment) {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.$t('order.pick-payment-method-error'))
          }

          if(!this.shipping.id) {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.$t('order.pick-shipping-method-error'))
          }
        
        }
      },
      setIdPromo () {
        if (this.is_voucher) {
          return this.voucher.id_promo
        } else {
          if (this.is_promo) {
            return this.promo.id_promo ? '' : this.promo_code
          } else {
            return ''
          }
        }
      },
      setPromoCode () {
        if (this.is_voucher) {
          return this.voucher.kode_promo
        } else {
          if (this.is_promo) {
            return this.promo.id_promo ? '' : this.promo_code
          } else {
            return ''
          }
        }
      },
      setPromoValue () {
        if (this.is_voucher) {
          return true
        } else {
          if (this.is_promo) {
            return true
          } else {
            return false
          }
        }
      },
      updateVoucherCode () {
        this.$store.commit('setIsLoading', true)

        let body = {
          kode_promo: this.voucher.kode_promo,
          total_transaction: this.total,
          delivery_price: this.shipping.tarif,
          products:  this.cart.map(item => {
            return {
              sku_product: item.size.sku_product,
              qty: item.quantity
            }
          })
        }

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios
          .post('/api/v1/orders/promo', body)
          .then(response => {
            localStorage.setItem('prepp_v', crypto.encrypt(response.data.data))
            this.$store.commit('setVoucher', response.data.data)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name});
        }
      },
      apiInitalCheckout (ids) {
        let current_timestamp = Math.floor(new Date() / 1000);
        apiCoversion.post('events?access_token='+ process.env.VUE_APP_FB_ACCESS_TOKEN, {
          data: [
            {
              event_name: 'InitialCheckout',
              event_time: current_timestamp,
              action_source: 'website',
              user_data: {
                  em: [
                      null
                  ],
                  ph: [
                      null
                  ]
              },
              custom_data: {
                content_type: 'product_group',
                content_ids: ids,
                value: this.total,
                nums_items: this.cartNumber,
                currency: 'IDR'
              }
            }
          ]
        })
          .then(response => {
            this.coversionResponse = response
          })
          .catch(error => {
            this.coversionResponse = error
          })
      },
      apiPurchase (ids) {
        let current_timestamp = Math.floor(new Date() / 1000);
        apiCoversion.post('events?access_token='+ process.env.VUE_APP_FB_ACCESS_TOKEN, {
          data: [
            {
              event_name: 'Purchase',
              event_time: current_timestamp,
              action_source: 'website',
              user_data: {
                  em: [
                      null
                  ],
                  ph: [
                      null
                  ]
              },
              custom_data: {
                content_type: 'product_group',
                content_ids: ids,
                value:  (this.total + this.shipping.tarif + this.paymentFee + this.shipping.packing_fee - this.promo.total_promo - this.voucher_price),
                nums_items: this.cartNumber,
                currency: 'IDR'
              }
            }
          ]
        })
          .then(response => {
            this.coversionResponse = response
          })
          .catch(error => {
            this.coversionResponse = error
          })
      },
      apiAddPaymentInfo () {
        let ids = this.cart.map(item => {
          return item.isBundling ? item.data.product_code :item.size.sku_product
        })
        let current_timestamp = Math.floor(new Date() / 1000);
        apiCoversion.post('events?access_token='+ process.env.VUE_APP_FB_ACCESS_TOKEN, {
          data: [
            {
              event_name: 'AddPaymentInfo',
              event_time: current_timestamp,
              action_source: 'website',
              user_data: {
                  em: [
                      null
                  ],
                  ph: [
                      null
                  ]
              },
              custom_data: {
                content_type: 'product_group',
                content_ids: ids,
                value: this.total,
                nums_items: this.cartNumber,
                currency: 'IDR'
              }
            }
          ]
        })
          .then(response => {
            this.coversionResponse = response
          })
          .catch(error => {
            this.coversionResponse = error
          })
      }
    },
    mounted () {
      if (!this.isLogin) {
        this.$router.push({ name: 'Login'})
      } else {
        if (this.cart.length === 0) {
          this.$router.push({ name: 'Home'})
        } else {
          if (this.shipping) {
            this.setTotalAmount()
            this.selectedShipping = this.shipping ? this.shipping.name : ''
          } else {
            this.$router.push({ name: 'Cart'})
          }
        }

        // if (this.voucher) {
        //   this.is_voucher = true
        // }

        localStorage.removeItem('prepp_v')
        this.$store.commit('setVoucher', null)
        
      }

      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setActivePage', 'CHECKOUT')
    }
  }
</script>

<style scoped>
  .checkout {
    border: 1px solid #C0C0C0;
    margin: 128px 0;
    padding: 20px;
  }

  .checkout-m {
    border: 1px solid #C0C0C0;
    margin: 96px 0;
    padding: 20px;
  }

  .tbl {
    width: 100%;
  }

  .text {
    color: #000000;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-r {
    color: red;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-rv {
    color: red;
    margin-bottom: 2px;
    font-size: 0.6rem !important;
    font-style: normal !important;
  }

  .tracking-text {
    color: #808080;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
    cursor: pointer;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
    text-transform: uppercase;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
    font-weight: bold;
  }

  .f-smaller {
    color: #808080;
    font-size: 0.7rem !important;
  }

  .subtitle-gray {
    color: #808080;
    margin-bottom: 0;
    font-size: 0.8rem !important;
  }

  .text-2 {
    margin: 0;
    font-size: 0.8rem !important;
    color: #000000;
    font-weight: bold;
  }

  .p-text-gray {
    margin: 0;
    font-size: 0.7rem !important;
    color: #808080;
  }

  .responsive {
    max-width: 125px !important;
    width: 100%;
    height: auto;
    position: relative;
    word-wrap: break-word;
  }

  .qr-code {
    max-width: 200px !important;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .pr {
    float: right;
  }

  .red {
    color: red;
  }

  .order-detail {
    max-height: 500px;
    overflow-y: auto;
  }

  .amount {
    border: 1px solid #808080;
    padding: 15px;
  }

  .pointer {
    cursor: pointer;
  }

  .disable-row {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .radio {
    margin: 20px 0;
  }

  .radio .item {
    margin: 10px 10px;
    cursor: pointer;
  }

  .radio img {
    margin: 0 20px;
    width: 50px;
  }

  .radio label {
    font-size: 12px !important;
  }

  .radio .description {
    font-size: 12px;
    text-align: justify;
    text-justify: inter-word;
    border: 1px solid #c0c0c0;
    padding: 10px;
  }

  .line-thru {
    text-decoration: line-through;
  }

</style>
